<template>
  <div>6666</div>
</template>

<script>
  export default {
    name: 'Checkfor'
  }
</script>

<style scoped>

</style>
